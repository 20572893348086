<template>
    <div class="container">
      <div style="height: 3px"></div>
        <div class="progress">
            <div class="position">
                <div class="bar">
                    <div class="line"></div>
                    <div>
                      <div class="steps">
                        <div class="role">
                          <div class="icon"></div>
                        </div>
                        <div class="box">
                          <img src="@/assets/images/home/img_rwbx_0.png">
                        </div>
                        <div class="box">
                          <img src="@/assets/images/home/img_rwbx_0.png">
                        </div>
                        <div class="box">
                          <img src="@/assets/images/home/img_rwbx_0.png">
                        </div>
                        <div class="box">
                          <img src="@/assets/images/home/img_rwbx_0.png">
                        </div>
                      </div>
                    </div>
                    <div class="line1">
                      <div class="line1-1">Horário de Resetar o Nível da Atividade: <span>24:00:00</span></div>
                    </div>
                </div>
            </div>
        </div>
      <div class="bar2">
        <div class="bar2-left">
          <div class="bar2-left-1">Benefícios para novos jogadores</div>
          <div class="bar2-left-2" @click="toUrl('/home/records')">Histórico</div>
        </div>
        <div class="bar2-right">
          <div class="bar2-right-1">
            <div class="bar2-right-1-1">
              <div class="bar2-right-1-1-1">
                <img style="width: 30px" src="@/assets/img_dr.png" alt="">
                <span>Baixe, instale e faça login pela primeira vez no aplicativo(Complete o primeiro depósito)</span>
              </div>
              <div class="bar2-right-1-1-2">Bônus  <span>2.44</span></div>
            </div>
            <div class="bar2-right-1-2">Prosseguir</div>
          </div>
          <div class="bar2-right-1">
            <div class="bar2-right-1-1">
              <div class="bar2-right-1-1-1">
                <img style="width: 30px" src="@/assets/img_sjyz.png" alt="">
                <span>Verificação por SMS</span>
              </div>
              <div class="bar2-right-1-1-2">Bônus  <span>2.44</span></div>
            </div>
            <div class="bar2-right-1-2">Prosseguir</div>
          </div>
          <div class="bar2-right-2">
            <div>I.Conteúdo da tarefa:</div>
            <p style="text-align: justify">1. Cada conta recém-registrada pode completar as tarefas acima. Depois de completar as tarefas, você pode obter uma certa quantia de bônus. Quanto maior a dificuldade, mais recompensas;
              <br>2. Como cada conta é completamente anônima, uma vez roubada, a perda de fundos não será recuperada, por isso exigimos forçosamente uma verificação vinculativa em duas etapas, especialmente a adição do endereço de retirada, que comprovadamente foi feito por mim para protegê-lo.segurança;
              <br>3. Você pode recebê-lo diretamente se atender às condições. Você pode recebê-lo diretamente em qualquer terminal H5, Android e iOS. Ele será inválido após a expiração (ou seja, o não recebimento ativo será considerado como abandono voluntário );
              <br>4. Como o bónus dado nesta tarefa é relativamente elevado, o bónus requer um volume de negócios de 8,00 vezes (ou seja, auditoria, codificação ou apostas válidas) antes de poder ser retirado. A codificação é apenas para pesca e electrónica;
              <br>5. Esta tarefa é limitada às operações humanas normais do proprietário da conta. É proibido alugar, usar plug-ins, robôs, jogar com contas diferentes, escovar uns aos outros, arbitragem, interfaces, protocolos, explorar brechas, controle de grupo ou outros meios técnicos para participar, caso contrário será cancelado ou terá recompensas deduzidas, congeladas ou mesmo colocadas na lista negra;
              <br>6. Os seguintes tipos de jogos: [Baccarat、Red and Black War、Dragon Tiger Fight, Sic Bo、jogos de desporto] não participam desta atividade, caso contrário, o bônus e os ganhos serão anulados. Em caso de arbitragem no Baccarat ou em qualquer outro jogo, nossa empresa se reserva o direito de cancelar todos os seus ganhos.
              <br>7. O número da conta bancária e quaisquer informações não podem ser alterados antes do primeiro pedido de saque. Caso precise ser modificado, o bônus e o lucro gerado pelo bônus serão deduzidos;
              <br>8. Para evitar diferenças na compreensão do texto, a plataforma reserva-se o direito de interpretação final deste evento.
            </p>
          </div>
        </div>
      </div>
      <div style="height: 50px"></div>
      <tabbar></tabbar>
    </div>
</template>
<script>
import tabbar from "@/components/Tabbar.vue";
export default {
  data(){
    return{

    }
  },
  components:{tabbar},
  methods:{
    toUrl(url) {
      this.$router.push(url)
    }
  }
}
</script>
<style scoped lang="scss">
.container{
  width: 100%;
  min-width: 320px;
}
.progress {
    width: 94%;
    //height: 80px;
    background: #1c1c1c;
    border-radius: 6px;
    margin: 10px;
    box-sizing: border-box;
}

.position {
    width: 90%;
    height: 100%;
    display: flex;
    margin: 10px auto;

}

.bar {
    width: 100%;
    position: relative;
    margin-top: 10px;

    .line {
        position: absolute;
        width: 90%;
        height: 6px;
        left: 14px;
        top: 0.24rem;
        background: #777777;
    }

    .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;

        .box {
            width: 0.56rem;
            height: 0.56rem;
            border-radius: 50%;
            background: #1c1c1c;
            border: 1px solid #777777;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 30px;
            }
        }

    }
  .line1{
    margin-top: 70px;
    margin-bottom: 10px;
    .line1-1{
      text-align: center;
      border: 1px solid #777;
      border-radius: 10px;
      color:#777;
      font-size: 12px;
    }
  }
}
.bar2{
  display: flex;
  justify-content: center;
  //align-items: center;
  padding: 15px;
  .bar2-left{
    height: 100%;
    width: 20%;
    .bar2-left-1{
      font-size: 10px;
      background: #feb705;
      border-radius: 5px;
      border:1px solid;
      color: #000;
      text-align: center;
      margin: 10px 0;
      cursor: pointer;
    }
    .bar2-left-2{
      font-size: 12px;
      background: #feb705;
      border-radius: 5px;
      border:1px solid;
      color: #000;
      padding: 5px;
      cursor: pointer;
      text-align: center;
    }
  }
  .bar2-right{
    height: 100%;
    width: 80%;
    margin-left: 10px;
    .bar2-right-1{
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #1c1c1c;
      border-radius: 5px;
      .bar2-right-1-1{
        display: flex;
        flex-direction: column;
        .bar2-right-1-1-1{
          display: flex;
          align-items: center;
        font-size: 10px;
        }
        .bar2-right-1-1-2{
          font-size: 10px;
          padding: 5px;
          span{
            color: #feb705;
          }
        }
      }
      .bar2-right-1-2{
        background: #feb705;
        color: #000;
        padding: 5px;
        margin: 0 5px;
        font-size: 12px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    .bar2-right-2{
      background: #1c1c1c;
      border-radius: 5px;
      padding: 10px;
      font-size: 10px;
    }
  }
}

.role {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #40bd0f96;
    position: relative;

    .icon {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #41bd0f;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
